import React from "react";
import PropTypes from "prop-types";
// import { Helmet } from "react-helmet-async";
import posed from "react-pose";
import SocialMediaBlock from "../SocialMediaBlock";
import GitHub from "../GitHub";
import GitLab from "../GitLab";
import Facebook from "../Facebook";
import Linkedin from "../Linkedin";

const AnimatedCommentBar = posed.span({
  hoverable: true,
  draggable: true,
  init: {
    cursor: "pointer",
    boxShadow: "-10px -10px 20px rgba(161, 0, 246, 0)",
    transformOrigin: "50% 50% 0px",
  },
  enter: {
    scale: 1,
    opacity: 0.8,
    rotate: "200deg",
    transition: {
      scale: {
        ease: "easeInOut",
        duration: 400,
      },
      boxShadow: {
        ease: "easeInOut",
        duration: 200,
      },
      rotate: {
        ease: "anticipate",
        duration: 1600,
      },
    },
  },
  exit: {
    scale: 1,
    opacity: 0,
    rotate: "0deg",
  },
  hover: {
    opacity: 1,
  },
  drag: {
    scale: 1.1,
    boxShadow: "0px 0px 20px rgba(161, 0, 246, 0.6)",
    opacity: 1,
  },
});

const SocialContainer = (props: any) => (
  <>
    <div
      className={`social-media-container${props.footer ? "-footer" : ""} ${
        props.containerClassName
      }`}
      style={{ left: props.left }}
    >
      <SocialMediaBlock
        link="https://github.com/mosano-eu"
        label="github"
        image={
          <GitHub
            fill={props.invert ? "white" : "#1e22aa"}
            yellow={!!props.revert}
          />
        }
      />
      <SocialMediaBlock
        link="https://gitlab.com/mosano"
        label="gitlab"
        image={
          <GitLab
            fill={props.invert ? "white" : "#1e22aa"}
            yellow={!!props.revert}
          />
        }
      />
      <SocialMediaBlock
        link="https://www.linkedin.com/company/mosano/"
        label="linkedin"
        image={
          <Linkedin
            fill={props.invert ? "white" : "#1e22aa"}
            yellow={!!props.revert}
          />
        }
      />
      <SocialMediaBlock
        link="https://www.facebook.com/mosanotech/"
        label="facebook"
        image={
          <Facebook
            fill={props.invert ? "white" : "#1e22aa"}
            yellow={!!props.revert}
          />
        }
      />
      {/* <Helmet>
        <script
          type="text/javascript"
          src="https://widget.clutch.co/static/js/widget.js"
        />
      </Helmet>
      <div
        className="clutch-widget"
        data-url="https://widget.clutch.co"
        data-widget-type="1"
        data-height="45"
        data-clutchcompany-id="1342656"
      /> */}
    </div>
    {props.animation ? (
      <div className="animation-container" style={{ left: props.left }}>
        <AnimatedCommentBar
          initialPose="init"
          pose="enter"
          className="left-rec"
          key="left"
        />
        <AnimatedCommentBar
          initialPose="init"
          pose="enter"
          className="right-rec"
          key="right"
        />
      </div>
    ) : null}
  </>
);

export default SocialContainer;

SocialContainer.propTypes = {
  left: PropTypes.string,
  bottom: PropTypes.string,
  marginTop: PropTypes.string,
  animation: PropTypes.bool,
  footer: PropTypes.bool,
  invert: PropTypes.bool,
  revert: PropTypes.bool,
  containerClassName: PropTypes.string,
};
