/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import "typeface-lora";
import "typeface-source-sans-pro";
import "@reach/skip-nav/styles.css";

import React, { FC, useMemo } from "react";
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";

import { PageProps } from "gatsby";
import { ThemeProvider } from "@emotion/react";
import { HelmetProvider } from "react-helmet-async";

import SEO from "./components/SEO";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SkipNavLink from "./components/SkipNavLink";

import getTransitionStyles from "./utils/get-transition-styles";

import { theme } from "./styles";

const TIMEOUT = 350;
const CHATWOOT_OPEN_DELAY = 10 * 1000;
const MOBILE_BREAKPOINT = 768;

export interface LayoutProps extends PageProps {
  customSEO?: boolean;
}

const Layout: FC<LayoutProps> = ({ children, customSEO, location }) => {
  const isBrowser = typeof window !== 'undefined'
  
  const isMobile = useMemo(
    () => { if (isBrowser) { return window?.innerWidth <= MOBILE_BREAKPOINT }},
    [isBrowser]
  )
  
  const transitionStyles = useMemo(
    () => getTransitionStyles(TIMEOUT, location.pathname),
    [location.pathname]
  );

  useMemo(
    () => {
      if (!isMobile) {
        // @ts-ignore
        return setTimeout(() => window?.$chatwoot?.toggle("open"), CHATWOOT_OPEN_DELAY )
      }
    }, []
  );

  const path =
    (typeof window !== "undefined" && window.location.pathname) ||
    location.pathname ||
    "";
  if (path.match(/^\/(offline-plugin)/)) {
    return null;
  }

  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        timeout={{ enter: TIMEOUT, exit: TIMEOUT }}
      >
        {(status) => (
          <div
            className="transition-group"
            style={{
              backgroundColor: "white",
              ...transitionStyles[status],
            }}
          >
            <HelmetProvider>
              <ThemeProvider theme={theme}>
                <Header path={path} />
                <SkipNavLink>Jump to site content</SkipNavLink>

                {!customSEO && <SEO />}
                <div
                  id="page__content-container"
                  style={{ backgroundColor: "white" }}
                >
                  {children}
                </div>
                <Footer path={path} />
              </ThemeProvider>
            </HelmetProvider>
          </div>
        )}
      </ReactTransition>
    </TransitionGroup>
  );
};

Layout.defaultProps = {
  customSEO: false,
};

export default Layout;
