import React from "react";
import PropTypes from "prop-types";

const GitHub = ({ fill, yellow }) => (
  <svg
    width="34px"
    className={yellow ? "hover-social-yellow" : "hover-social"}
    height="34px"
    viewBox="0 0 36 34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g transform="translate(-630.000000, -57.000000)" fillRule="nonzero">
        <g transform="translate(140.000000, 56.000000)">
          <g transform="translate(489.000000, 0.000000)">
            <g>
              <rect
                fill="#000000"
                opacity={0}
                x={0}
                y={0}
                width={38}
                height={38}
              />
              <path
                d="M19,1.58333335 C9.48315207,1.48662618 1.68771627,9.11906651 1.58333335,18.6358333 C1.61946259,26.0458432 6.42835625,32.5877291
                13.49,34.8333333 C14.3608333,34.9916667 14.6775,34.4691667 14.6775,34.01 L14.6775,31.1125 C9.8325,32.1416667 8.80333335,28.8325
                8.80333335,28.8325 C8.48080291,27.793675 7.79511763,26.9056564 6.87166665,26.3308333 C5.28833331,25.2858333 6.99833331,25.3016667
                6.99833335,25.3016667 C8.10710961,25.4498962 9.08708568,26.0973803 9.65833335,27.0591667 C10.6805536,28.8317172 12.9343815,29.4585631
                14.725,28.4683333 C14.8135196,27.6012717 15.206162,26.7935504 15.8333333,26.1883333 C11.97,25.7608333 7.91666669,24.3041667 7.91666665,
                17.765 C7.88211864,16.0592428 8.51238421,14.406925 9.67416665,13.1575 C9.14505965,11.693025 9.20753616,10.0799952 9.84833335,8.66083335
                C9.84833335,8.66083335 11.3208333,8.20166669 14.5983333,10.4025 C17.4513322,9.6422443 20.4536678,9.6422443 23.3066667,10.4025 C26.6316667
                ,8.20166665 28.0566667,8.66083335 28.0566667,8.66083335 C28.6974638,10.0799952 28.7599404,11.693025 28.2308333,13.1575 C29.4196031,14.3840245
                30.0840455,16.0252539 30.0833333,17.7333333 C30.0833333,24.2883333 25.9983333,25.7291667 22.1666667,26.1566667 C23.0162069,26.9814809 23.4518876,28.1432961
                23.3541667,29.3233333 L23.3541667,33.9941667 C23.3541667,34.5483333 23.6708333,34.9916667 24.5416667,34.8175 C31.5822095,32.55831 36.3731632,26.0298371 36.4166667
                ,18.6358333 C36.3122837,9.11906651 28.5168479,1.48662618 19,1.58333335"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GitHub;

GitHub.propTypes = {
  fill: PropTypes.string,
  yellow: PropTypes.bool,
};
