import React, { FC, useState, useEffect } from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import MobileMenu from "../MobileMenu";
import NavButton from "../NavButton";
import logoWhite from "../../../assets/logos/mosano-white.svg";
import logo from "../../../assets/logos/mosano.svg";

const useInvert = (path: string) => {
  const matchUrl = (path: string) =>
    path.match(/^\/(about|case-studies|services|blog)/);
  const [invert, setInvert] = useState(!!matchUrl(path));

  useEffect(() => setInvert(!!matchUrl(path)), [path]);

  return invert;
};

export interface HeaderProps {
  path: string;
}

const Header: FC<HeaderProps> = ({ path }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const invert = useInvert(path);

  return (
    <div data-collapse="medium" className="navbar w-nav" style={{ zIndex: 3 }}>
      <div className="div-block-8">
        <nav>
          <Link to="/" className="brand-2 w-nav-brand w--current">
            <img
              src={invert ? logoWhite : logo}
              height={32}
              alt="Mosano"
              className="header-logo"
              key={path}
            />
          </Link>
        </nav>
        <nav role="navigation" className="nav-menu w-nav-menu">
          <Link
            to="/about"
            className={classnames("menu-title w-nav-link", {
              white: invert,
              "menu-selected": path === "/about",
            })}
          >
            about
          </Link>
          <Link
            to="/services"
            className={classnames("menu-title w-nav-link", {
              white: invert,
              "menu-selected": path.match(/services/),
            })}
          >
            services
          </Link>
          <Link
            to="/case-studies"
            className={classnames("menu-title w-nav-link", {
              white: invert,
              "menu-selected": path.match(/case-studies/),
            })}
          >
            case studies
          </Link>
          <Link
            to="/team"
            className={classnames("menu-title w-nav-link", {
              white: invert,
              "menu-selected": path === "/team",
            })}
          >
            team
          </Link>
          <Link
            to="/blog"
            className={classnames("menu-title w-nav-link", {
              white: invert,
              "menu-selected": path.match(/blog|post/),
            })}
          >
            blog
          </Link>
          <Link
            to="/contact"
            className={classnames("menu-title w-nav-link", {
              white: invert,
              "menu-selected": path === "/contact",
            })}
          >
            contact
          </Link>
          <Link
            to="/join-us"
            className={classnames("menu-title w-nav-link", {
              white: invert,
              "menu-selected": path.match(/jobs|join-us/),
            })}
          >
            join us
          </Link>
        </nav>

        <NavButton
          label="Menu"
          color={invert ? "white" : "#0c0d44"}
          type={`menuHamburguer${invert ? "White" : ""}`}
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        />
      </div>

      <MobileMenu
        visible={mobileMenuOpen}
        onClickLink={() => setMobileMenuOpen(!mobileMenuOpen)}
      >
        <NavButton
          label="Close"
          type="menuCloseWhite"
          color="white"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        />
      </MobileMenu>
    </div>
  );
};

export default Header;
