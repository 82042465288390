// Global Styles
import "./assets/css/webflowGenericStyles.css";
import "./assets/css/webflowMosanoStyles.css";
import "./assets/css/webflowNormalize.css";
import "./assets/css/mobileMenu.css";
import "./assets/css/override.css";
import "./assets/css/custom.css";

// Site-Wide Wrapper Components
// @ts-ignore
import CookieConsent from "./src/wrappers/CookieConsent.tsx";

export const wrapPageElement = CookieConsent;

const transitionDelay = 520;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (typeof window === "undefined") return false;
  if (location.action === "PUSH") {
    window.setTimeout(
      () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }),
      transitionDelay
    );
  } else {
    const savedPosition = getSavedScrollPosition(location);
    const position = [...(savedPosition || [0, 0])];
    window.setTimeout(
      () =>
        window.scrollTo({
          top: position[0],
          left: position[1],
          behavior: "smooth",
        }),
      transitionDelay
    );
  }
  return false;
};
