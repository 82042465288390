import React from "react";
import PropTypes from "prop-types";

const SocialMediaBlock = (props: any) => (
  <a
    href={props.link}
    rel="noopener noreferrer"
    target="_blank"
    aria-label={props.label}
    className="social-media-block-1 w-inline-block"
  >
    {props.image}
  </a>
);

export default SocialMediaBlock;

SocialMediaBlock.propTypes = {
  link: PropTypes.string,
  image: PropTypes.object,
  label: PropTypes.string,
};
