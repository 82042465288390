import React from "react";
import PropTypes from "prop-types";

const Linkedin = ({ fill, yellow }) => (
  <svg
    width="34px"
    className={yellow ? "hover-social-yellow" : "hover-social"}
    height="34px"
    viewBox="0 0 34 34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g transform="translate(-727.000000, -58.000000)" fillRule="nonzero">
        <g transform="translate(140.000000, 56.000000)">
          <g transform="translate(489.000000, 0.000000)">
            <g transform="translate(96.000000, 0.000000)">
              <rect
                fill="#000000"
                opacity={0}
                x={0}
                y={0}
                width={38}
                height={38}
              />
              <path
                d="M14.288,26.212875 L14.288,15.093125 L10.590125,15.093125 L10.590125,26.2140625
               L14.288,26.2140625 L14.288,26.212875 Z M12.4390625,13.573125 C13.7275,13.573125
                14.5314375,12.718125 14.5314375,11.6505625 C14.5076875,10.5604375 13.7275,9.730375
                 12.4651875,9.730375 C11.198125,9.730375 10.3728125,10.561625 10.3728125,11.6505625
                  C10.3728125,12.7193125 11.1755625,13.5743125 12.4165,13.5743125 L12.44025,13.5743125
                   L12.4390625,13.573125 Z M27.6176875,26.212875 L27.6176875,19.8371875 C27.6176875,16.42075
                    25.7936875,14.8306875 23.362875,14.8306875 C21.39875,14.8306875 20.52,15.9113125 20.03075,16.66775
                     L20.03075,15.09075 L16.3340625,15.09075 C16.38275,16.13575 16.3340625,26.212875 16.3340625,26.212875
                      L20.03075,26.212875 L20.03075,20.00225 C20.03075,19.66975 20.0545,19.33725 20.151875,19.102125
                       C20.417875,18.437125 21.0270625,17.7495625 22.047125,17.7495625 C23.3854375,17.7495625
                        23.923375,18.7684375 23.923375,20.2635 L23.923375,26.2140625 L27.618875,26.2140625
                         L27.6176875,26.212875 Z M19,2.375 C28.18175,2.375 35.625,9.8170625 35.625,19
                          C35.625,28.18175 28.18175,35.625 19,35.625 C9.8170625,35.625 2.375,28.18175 2.375,19
                           C2.375,9.8170625 9.8170625,2.375 19,2.375 Z"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Linkedin;

Linkedin.propTypes = {
  fill: PropTypes.string,
  yellow: PropTypes.bool,
};
