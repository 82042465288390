import React, { FC } from "react";

const CookieConsent: FC<any> = ({ element, props }) => {
  return (
    <>
      {element}
    </>
  );
};

export default CookieConsent;
