import React from "react";
import PropTypes from "prop-types";
import menuHamburguer from "../../../assets/images/menuHamburguer.svg";
import menuHamburguerWhite from "../../../assets/images/menuHamburguerWhite.svg";
import menuClose from "../../../assets/images/menuClose.svg";
import menuCloseWhite from "../../../assets/images/menuCloseWhite.svg";

const NavButton = (props: any) => {
  const imgSrc =
    (props.type === "menuHamburguer" && menuHamburguer) ||
    (props.type === "menuClose" && menuClose) ||
    (props.type === "menuHamburguerWhite" && menuHamburguerWhite) ||
    (props.type === "menuCloseWhite" && menuCloseWhite) ||
    menuHamburguer;

  return (
    <div
      className="w-nav-button"
      onClick={props.onClick}
      style={props.type === "menuCloseWhite" ? { zIndex: 1 } : {}}
    >
      <img src={imgSrc} className="menuHamburguer" />
      <div className="menuText" style={{ color: props.color }}>
        {props.label}
      </div>
    </div>
  );
};

export default NavButton;

NavButton.propTypes = {
  visible: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
};
