// eslint-disable-next-line no-undef
module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "Mosano", // Navigation and Site Title
  titleAlt: "Mosano", // Title for JSONLD
  description: "The Mobile & Web Development Studio",
  headline: "Precise Technology", // Headline for schema.org JSONLD
  url: "https://mosano.eu", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  logo: "/logos/mosano.svg", // Used for SEO
  ogLanguage: "en_US", // Facebook Language

  // JSONLD / Manifest
  favicon: "./assets/images/favicon.png", // Used for manifest favicon generation
  shortName: "Mosano", // shortname for manifest. MUST be shorter than 12 characters
  author: "Mosano", // Author for schemaORGJSONLD
  themeColor: "#3D63AE",
  backgroundColor: "#EBEDF2",

  twitter: "@mosanotech", // Twitter Username
  facebook: "Mosano", // Facebook Site Name
  // googleAnalyticsID: "UA-105994269-1",
  googleAnalyticsV4ID: "G-7P9BS9EJ6X",

  skipNavId: "reach-skip-nav", // ID for the "Skip to content" a11y feature
};
