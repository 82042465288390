import React from "react";
import PropTypes from "prop-types";

const Facebook = ({ fill, yellow }) => (
  <svg
    width="34px"
    className={yellow ? "hover-social-yellow" : "hover-social"}
    height="34px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>facebook</title>
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g transform="translate(-776.000000, -59.000000)" fillRule="nonzero">
        <g transform="translate(140.000000, 56.000000)">
          <g transform="translate(489.000000, 0.000000)">
            <g transform="translate(144.000000, 0.000000)">
              <rect
                fill="#000000"
                opacity={0}
                x={0}
                y={0}
                width={38}
                height={38}
              />
              <path
                d="M34.98128,19.02204 C34.98128,26.6228 29.6704,32.97108 22.5606,34.5914 L22.5606,21.31572
                   L26.40088,21.31572 L26.40088,16.88264 L22.52032,16.88264 L22.52032,15.89312 C22.52032,13.99312
                    23.35176,12.4488 25.3308,12.4488 C26.12348,12.4488 26.75656,12.56812 27.27184,12.7262
                     L27.5082,8.09476 C26.67676,7.85764 25.64848,7.69804 24.30328,7.69804 C22.56136,7.69804
                      20.463,8.21408 18.95972,9.63832 C17.21704,11.22216 16.50492,13.79476 16.50492,16.12948
                       L16.50492,16.88188 L13.97184,16.88188 L13.97184,21.31572 L16.50492,21.31572 L16.50492,34.78976
                        C8.86768,33.5882 3.02024,26.99672 3.02024,19.02204 C3.02024,10.19616 10.17564,3.04076
                         19.00152,3.04076 C27.8274,3.04076 34.98128,10.19616 34.98128,19.02204 Z"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Facebook;

Facebook.propTypes = {
  fill: PropTypes.string,
  yellow: PropTypes.bool,
  hoverSocial: PropTypes.string,
};
