import React from "react";
import PropTypes from "prop-types";

const GitLab = ({ fill, yellow }) => (
  <svg
    width="34px"
    className={yellow ? "hover-social-yellow" : "hover-social"}
    height="34px"
    viewBox="0 0 34 34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g transform="translate(-679.000000, -58.000000)" fillRule="nonzero">
        <g transform="translate(140.000000, 56.000000)">
          <g transform="translate(489.000000, 0.000000)">
            <g transform="translate(48.000000, 0.000000)">
              <rect
                fill="#000000"
                opacity={0}
                x={0}
                y={0}
                width={38}
                height={38}
              />
              <path
                d="M19.005,2.38 C28.18675,2.38 35.63,9.8220625 35.63,19.005 C35.63,28.18675 28.18675,35.63
               19.005,35.63 C9.8220625,35.63 2.38,28.18675 2.38,19.005 C2.38,9.8220625 9.8220625,2.38 19.005,2.38
                Z M26.2700505,9.38 C26.0896045,9.38 25.9293566,9.49387679 25.8720745,9.66281338 L23.4321196,17.0746181
                 L15.3278804,17.0746181 L12.8900963,9.66281338 C12.8328142,9.49387679 12.6725663,9.38 12.4921203,9.38
                  C12.3116742,9.38 12.1514264,9.49387679 12.0941442,9.66281338 L8.42191085,20.8137397 C8.3081581,21.155774
                   8.43129835,21.5311814 8.72654341,21.7424477 L19.38,29.38 L30.0334566,21.7360182 C30.3287017,21.5247519
                    30.4518419,21.1493445 30.3380891,20.8073102 L30.3380891,20.8137397 L26.6680265,9.66281338 C26.6107444,9.49387679
                     26.4504966,9.38 26.2700505,9.38 Z"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GitLab;

GitLab.propTypes = {
  fill: PropTypes.string,
  yellow: PropTypes.bool,
};
