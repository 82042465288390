import React, { FC } from "react";
import { Link } from "gatsby";
import SocialContainer from "../SocialContainer";

export interface FooterProps {
  path: string;
}

const Footer: FC<FooterProps> = ({ path }) => {
  const isHome = path === "/";

  return (
    <div className="footer">
      <div className="footer-container">
        {isHome ? null : <SocialContainer footer />}
        <div className="div-block-112">
          <div className="div-block-50">
            <Link to="/cookiespolicy" className="menu-title">
              Cookies policy
            </Link>
            <Link to="/privacypolicy" className="menu-title-left">
              Privacy Policy
            </Link>
          </div>
          <div className="div-block-35">
            <div className="text-block-15 footer-title-right">
              © {new Date().getFullYear()} MOSANO
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
