import React from "react";
import { TransitionStatus } from "react-transition-group";

let lastRoute = null;

const styles = {
  static: (): Record<TransitionStatus, React.CSSProperties> => ({
    entering: {
      position: `absolute`,
      left: 0,
      right: 0,
      width: "100%",
      zIndex: 2,
    },
    entered: {
      position: `absolute`,
      left: 0,
      right: 0,
      width: "100%",
      zIndex: 2,
    },
    exiting: {
      position: `absolute`,
      left: 0,
      right: 0,
      width: "100%",
      zIndex: 1,
    },
    exited: {
      position: `absolute`,
      left: 0,
      width: 0,
      zIndex: 1,
    },
    unmounted: {},
  }),
  right: (timeout: number): Record<TransitionStatus, React.CSSProperties> => ({
    entering: {
      transition: `left ${timeout}ms ease-in-out`,
      position: `absolute`,
      left: 0,
      right: 0,
      width: "100%",
      zIndex: 2,
    },
    entered: {
      transition: `left ${timeout}ms ease-in-out`,
      position: `absolute`,
      left: 0,
      right: 0,
      width: "100%",
      zIndex: 2,
    },
    exiting: {
      transition: `left ${timeout}ms ease-in-out`,
      position: `absolute`,
      left: "100%",
      width: "100%",
      zIndex: 3,
    },
    exited: {
      transition: `left ${timeout}ms ease-in-out`,
      position: `absolute`,
      left: "100%",
      width: "100%",
      zIndex: 3,
    },
    unmounted: {},
  }),
  bottom: (timeout: number): Record<TransitionStatus, React.CSSProperties> => ({
    entering: {
      transition: `top ${timeout}ms ease-in-out`,
      position: `absolute`,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      zIndex: 2,
    },
    entered: {
      transition: `top ${timeout}ms ease-in-out`,
      position: `absolute`,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      zIndex: 2,
    },
    exiting: {
      transition: `top ${timeout}ms ease-in-out`,
      position: `absolute`,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      zIndex: 1,
    },
    exited: {
      transition: `top ${timeout}ms ease-in-out`,
      position: `absolute`,
      top: "100%",
      left: 0,
      right: 0,
      width: "100%",
      zIndex: 1,
    },
    unmounted: {},
  }),
};

const getTransitionStyles = (timeout: number, pathName: string) => {
  const cameFromSubRoute = !!lastRoute && lastRoute.split("/").length === 3;
  const isSubRoute =
    !!lastRoute &&
    ((lastRoute.match(/^\/(post)/) && pathName.match(/^\/(blog)/)) ||
      (lastRoute.match(/^\/(service\/)/) && pathName.match(/^\/(services)/)) ||
      (lastRoute.match(/^\/(jobs\/)/) && pathName.match(/^\/(join-us)/)) ||
      (lastRoute.match(/^\/(case-studies\/)/) &&
        pathName.match(/^\/(case-studies)/)));
  lastRoute = pathName;

  if (cameFromSubRoute && isSubRoute) {
    return styles.static();
  }

  if (pathName.match(/^\/(post|service\/|jobs|case-studies\/)/)) {
    return styles.right(timeout);
  }
  if (pathName.match(/^\/(blog|about|contact|join-us)/)) {
    return styles.bottom(timeout);
  }
  return styles.bottom(timeout);
};

export default getTransitionStyles;
