import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import Facebook from "./Facebook";
import Twitter from "./Twitter";

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = (props) => {
  const {
    title,
    description,
    image,
    image1,
    keywords,
    canonical_url: url,
    author,
    date,
    content,
    dateCreated,
    datePublished,
    dateModified,
    tags,
    article,
    pathname,
  } = props;
  const seo = {
    title: title || "Mosano",
    description: description || "Mosano",
    image: image?.url || image1?.url || "",
    url: url ? url.url : "",
    keywords,
    date,
    author: author?.document.data.name,
    avatar: author?.document.data.photo.url,
    content: content?.html,
    dateCreated,
    datePublished,
    dateModified,
    tags,
  };
  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')
  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: "https://mosano.eu",
    headline: "Mosano",
    inLanguage: "EN-US",
    mainEntityOfPage: "https://mosano.eu",
    description: seo.description,
    name: seo.title,
    author: {
      "@type": "Organization",
      name: "Mosano",
    },
    copyrightHolder: {
      "@type": "Organization",
      name: "Mosano",
    },
    copyrightYear: new Date().getFullYear(),
    creator: {
      "@type": "Organization",
      name: "Mosano",
    },
    publisher: {
      "@type": "Organization",
      name: "Mosano",
    },
    datePublished: "2019-01-18T10:30:00+01:00",
    dateModified: Date.now(),
    image: {
      "@type": "ImageObject",
      url: seo.image,
    },
  };

  // const schemaOrganization = {
  //   "@context": "http://schema.org",
  //   "@type": "Organization",
  //   name: "Mosano",
  //   url: "https://mosano.eu",
  //   sameAs: [
  //     "https://twitter.com/mosanotech",
  //     "https://www.facebook.com/mosanotech",
  //     "https://www.linkedin.com/company/mosano",
  //     "https://www.instagram.com/mosanotech",
  //   ],
  //   address: {
  //     "@type": "PostalAddress",
  //     streetAddress: "Rua D Joao I, 256 Matosinhos",
  //     addressRegion: "Porto",
  //     postalCode: "4450-162",
  //     addressCountry: "PT",
  //   },
  // };

  const schemaOrgBlogPosting = {
    "@context": "http://schema.org",
    "@type": "BlogPosting",
    image: seo.image,
    url: `https://mosano.eu/blog/${pathname}`,
    headline: seo.title,
    alternativeHeadline: seo.description,
    dateCreated: seo.dateCreated,
    datePublished: seo.datePublished,
    dateModified: seo.dateModified,
    inLanguage: "en-US",
    isFamilyFriendly: "true",
    copyrightYear: seo.date,
    copyrightHolder: "Mosano",
    contentLocation: {
      "@type": "Place",
      name: "Oporto",
    },
    accountablePerson: {
      "@type": "Person",
      name: seo.author,
      url: "https://mosano.eu/team",
    },
    author: {
      "@type": "Person",
      name: seo.author,
      url: "https://mosano.eu/team",
    },
    creator: {
      "@type": "Person",
      name: seo.author,
      url: "https://mosano.eu/team",
    },
    publisher: {
      "@type": "Organization",
      name: seo.author,
      url: "https://mosano.eu/team",
      logo: {
        "@type": "ImageObject",
        url: seo.avatar,
        width: "400",
        height: "400",
      },
    },
    mainEntityOfPage: "True",
    keywords: [seo.keywords],
    genre: ["SEO", "JSON-LD"],
    articleSection: seo.tags,
    articleBody: seo.content,
  };

  // Initial breadcrumb list

  const itemListElement = [
    {
      "@type": "ListItem",
      item: {
        "@id": "https://mosano.eu",
        name: "Homepage",
      },
      position: 1,
    },
  ];

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    description: "Breadcrumbs list",
    name: "Breadcrumbs",
    itemListElement,
  };
  return (
    <>
      <Helmet title={seo.title}>
        <html lang="en-US" />
        <title>{seo.title || "Mosano"}</title>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="keywords" content={seo.keywords} />
        <meta name="author" content="Mosano" />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        <script type="application/ld+json">
          {JSON.stringify(article ? schemaOrgBlogPosting : schemaOrgWebPage)}
        </script>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type="website"
        url={seo.url}
        locale="en-us"
        name="Mosano"
      />
      <Twitter
        title={seo.title}
        image={seo.image}
        desc={seo.description}
        username="mosanotech"
      />
    </>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  author: PropTypes.object,
  canonical_url: PropTypes.object,
  content: PropTypes.object,
  keywords: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  image1: PropTypes.object,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  date: PropTypes.string,
  dateCreated: PropTypes.string,
  datePublished: PropTypes.string,
  dateModified: PropTypes.string,
  tags: PropTypes.array,
};

SEO.defaultProps = {
  author: null,
  canonical_url: null,
  title: null,
  content: null,
  date: null,
  dateCreated: null,
  datePublished: null,
  tags: null,
  dateModified: null,
  keywords: "",
  description: null,
  image: null,
  image1: null,
  pathname: null,
  article: false,
};
