import React, { FC } from "react";
import { css } from "@emotion/react";
import website from "../../config/website";
import theme from "../styles/theme";

const SkipNavLink: FC = ({ children, ...props }) => (
  <a
    {...props}
    href={`#${website.skipNavId}`}
    css={css`
      font-family: ${theme.fonts.primary};
      color: ${theme.colors.bg};
      background-color: ${theme.colors.primary} !important;
      text-decoration: none;
    `}
    data-reach-skip-link
  >
    {children}
  </a>
);

export default SkipNavLink;

SkipNavLink.defaultProps = {
  children: "Skip to content",
};
