const theme = {
  colors: {
    primary: "#1E22AA",
    bg: "#FFF",
    secondary: "#E31C79",
    black: "#2D2926",
  },
  fonts: {
    primary: "Clan, sans-serif;",
    secondary: "'Clan news', sans-serif;",
  },
  maxWidth: "1000px",
  maxWidthText: "720px",
  breakpoints: {
    xs: "400px",
    s: "600px",
    m: "900px",
    l: "1200px",
  },
};

export default theme;
