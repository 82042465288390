import React, { FC } from "react";
import { Link } from "gatsby";
import { LocationProvider } from "@reach/router";
import PropTypes from "prop-types";
import SocialContainer from "../SocialContainer";

export interface MobileMenuProps {
  visible?: boolean;
  onClickLink?: () => void;
}

const MobileMenu: FC<MobileMenuProps> = (props) => (
  <LocationProvider>
    {(context) => {
      const path = context.location.pathname.split("/")[1];
      const pathClass = (link: string) =>
        path === link ? "nav-link-underline" : null;

      return (
        <div
          className="container-mobile mobile-background"
          style={{
            display: "block",
            left: props.visible ? 0 : "200%",
            background: path === "about" ? "#e31c79" : undefined,
          }}
        >
          <div className="navTop">{props.children}</div>
          <div className="nav-mobile">
            <div className="nav-mobile-links-container">
              <Link
                to="/about"
                className={`${pathClass("about")} nav-link`}
                onClick={props.onClickLink}
              >
                about
              </Link>
              <Link
                to="/services"
                className={`${pathClass("services")} nav-link`}
                onClick={props.onClickLink}
              >
                services
              </Link>
              <Link
                to="/case-studies"
                className={`${pathClass("case-studies")} nav-link`}
                onClick={props.onClickLink}
              >
                case studies
              </Link>
              <Link
                to="/team"
                className={`${pathClass("team")} nav-link`}
                onClick={props.onClickLink}
              >
                team
              </Link>
              <Link
                to="/blog"
                className={`${pathClass("team")} nav-link`}
                onClick={props.onClickLink}
              >
                blog
              </Link>
              <Link
                to="/contact"
                className={`${pathClass("contact")} nav-link`}
                onClick={props.onClickLink}
              >
                contact
              </Link>
              <Link
                to="/join-us"
                className={`${pathClass("join-us")} nav-link`}
                onClick={props.onClickLink}
              >
                join us
              </Link>
            </div>
            <div className="container__mosano-info">
              <a href="mailto:hello@mosano.eu" className="text__mosano-info p1">
                {" "}
                hello@mosano.eu{" "}
              </a>
              <a href="tel:+351300003520" className="text__mosano-info p1">
                {" "}
                +351 300003520{" "}
              </a>
              <p className="text__mosano-info call-cost__mosano-info p1">
                Phone call will be charged at your local rate
              </p>
            </div>
            <SocialContainer
              animation
              invert
              revert={path === "about"}
              containerClassName="pos-rel"
            />
          </div>
        </div>
      );
    }}
  </LocationProvider>
);

export default MobileMenu;
MobileMenu.propTypes = {
  visible: PropTypes.bool,
  onClickLink: PropTypes.func,
};
